const navMenuAction = () => {
    $('#toggle').on('click', () => {
        $('#toggle').toggleClass('active');
        $('header').find('.main-nav').toggleClass('active');
        $("#overlay").toggleClass("active");
    });
    $("#overlay").on('click', () => {
        $("#overlay").removeClass('active');
        $('#toggle').removeClass('active');
        $('header').find('.main-nav').removeClass('active');
    })
}
const footerActiion = () => {
    $(".menu-item-title p").on('click', function (e) {
        let $this = $(this);
        e.preventDefault();
        $this.parents("li").addClass("active");
        if ($this.parent().next().hasClass("show")) {
            $this.parent().next().removeClass("show");
            $this.parent().next().slideUp(350);
            $this.closest('li').removeClass("active");
        } else {
            $this.parent().parents().find(".sub-menu").removeClass("show");
            $this.parent().parents().find(".sub-menu").slideUp(350);
            $this.parent().parents('#nav-footer').find("li").removeClass("active");
            $this.parent().next().toggleClass("show");
            $this.parent().next().slideDown(350);
            $this.parents("li").addClass("active");
        }
    });
    if ($("#nav-footer li").hasClass("active")) {
        $("#nav-footer li.active").find(".sub-menu").slideDown(350);
    }
}
const swiperInit = () => {
    var main_banner = new Swiper("#main-banner .swiper", {
        spaceBetween: 0,
        autoplay: {
            delay: 4500,
        },
        effect: "creative",
        creativeEffect: {
            prev: {
                shadow: true,
                translate: ["-20%", 0, -1],
            },
            next: {
                translate: ["100%", 0, 0],
            },
        },
        speed: 500,
        observer: true,
        observeParents: true,
        pagination: {
            el: "#main-banner .swiper-pagination",
            clickable: true,
        },
        navigation: {
            prevEl: "#main-banner .swiper-button-prev",
            nextEl: "#main-banner .swiper-button-next",
        },
    });
    var serviceSlide = new Swiper(".home-2-slide .swiper", {
        spaceBetween: 30,
        autoplay: {
            delay: 4500,
        },
        effect: "creative",
        creativeEffect: {
            prev: {
                shadow: true,
                translate: ["-20%", 0, -1],
            },
            next: {
                translate: ["100%", 0, 1],
            },
        },
        speed: 500,
        observer: true,
        observeParents: true,
        pagination: {
            el: ".home-2-slide .swiper-pagination",
            clickable: true,
        },
        navigation: {
            prevEl: ".home-2-slide .swiper-button-prev",
            nextEl: ".home-2-slide .swiper-button-next",
        },
    });
    $(".home-3-slide .swiper").each(function (index, element) {
        var $this = $(this);
        $this.addClass("swiper-index-" + index);
        var swiper = new Swiper(".swiper-index-" + index, {
            loop: true,
            freeMode: true,
            spaceBetween: 16,
            slidesPerView: 2,
            loop: true,
            autoplay: {
                delay: 1,
                disableOnInteraction: true
            },
            freeMode: true,
            speed: 5000,
            freeModeMomentum: false
        });
        $(".swiper").mouseenter(function () {
            console.log("mouse over");
            swiper.autoplay.stop();
        });

        $(".swiper").mouseleave(function () {
            console.log("mouse leave");
            swiper.autoplay.start();
        });
    });
    $(".sigle-slide .swiper").each(function (index, element) {
        var $this = $(this);
        $this.addClass("swiper-index-" + index);
        $this.parent().find(".swiper-pagination").addClass("swiper-pagination-" + index);
        var sigleSilde = new Swiper(".swiper-index-" + index, {
            spaceBetween: 30,
            slidesPerView: 1,
            speed: 500,
            observer: true,
            loop: true,
            observeParents: true,
            pagination: {
                el: ".swiper-pagination-" + index,
                clickable: true,
            },

        });
    });
    var newsBanner = new Swiper(".home-4-slide .swiper", {
        spaceBetween: 30,
        slidesPerView: 1,
        speed: 500,
        observer: true,
        loop: true,
        observeParents: true,
        pagination: {
            el: ".home-4-slide .swiper-pagination",
            clickable: true,
        },
        navigation: {
            prevEl: ".home-4-slide .swiper-button-prev",
            nextEl: ".home-4-slide .swiper-button-next",
        },
        breakpoints: {
            1024: {
                slidesPerView: 3,
            },
        },
    });
    var newsBanner = new Swiper(".other-article .swiper", {
        spaceBetween: 30,
        slidesPerView: 1,
        speed: 500,
        observer: true,
        loop: true,
        observeParents: true,
        pagination: {
            el: ".other-article .swiper-pagination",
            clickable: true,
        },
        breakpoints: {
            1024: {
                slidesPerView: 3,
            },
        },
    });

}
const setBackgroundElement = () => {
    $("[setBackground]").each(function () {
        let background = $(this).attr("setBackground");
        $(this).css({
            "background-image": "url(" + background + ")",
            "background-size": "cover",
            "background-position": "center center",
            "background-repeat": "repeat"
        });
    });
};
const changeAtiveTab = () => {
    var theTabs = $('.nav-tabs li')
    var i

    function theTabClicks(tabClickEvent) {
        var clickedTab = tabClickEvent.currentTarget
        var tabParent = tabClickEvent.currentTarget.parentNode.parentNode.parentNode
        var theTabs = tabParent.querySelectorAll('.nav-tabs li')
        for (var i = 0; i < theTabs.length; i++) {
            theTabs[i].classList.remove('active')
        }

        clickedTab.classList.add('active')
        tabClickEvent.preventDefault()
        var contentPanes = tabParent.querySelectorAll('.panel')
        for (i = 0; i < contentPanes.length; i++) {
            contentPanes[i].classList.remove('active')
        }
        var anchorReference = tabClickEvent.target
        var activePaneId = anchorReference.getAttribute('href')
        var activePane = tabParent.querySelector(activePaneId)
        activePane.classList.add('active')
    }
    for (i = 0; i < theTabs.length; i++) {
        theTabs[i].addEventListener('click', theTabClicks)
    }
}
const scrollToTop = () => {
    var pagesBanner = $("#page-banner");
    var mainBanner = $("#main-banner");
    var btn_BackToTop = $("#back-to-top.mobile-show");
    var btn_BackToTopDesktop = $("#back-to-top.desktop-show");
    var btn_buddleChat = $('#buddleChat');
    $(window).on("scroll", function () {
        if (
            $(this).scrollTop() >= mainBanner.outerHeight() ||
            $(this).scrollTop() >= pagesBanner.outerHeight()
        ) {
            btn_BackToTop.addClass("active");
        } else {
            btn_BackToTop.removeClass("active");
        }
    });
    btn_BackToTop.on("click", function () {
        $("html,body").animate({
            scrollTop: 0,
        });
    });
    $(window).on("scroll", function () {
        if (
            $(this).scrollTop() >= mainBanner.outerHeight() ||
            $(this).scrollTop() >= pagesBanner.outerHeight()
        ) {
            btn_BackToTopDesktop.addClass("active");
        } else {
            btn_BackToTopDesktop.removeClass("active");
        }
    });
    btn_BackToTopDesktop.on("click", function () {
        $("html,body").animate({
            scrollTop: 0,
        });
    });

}
const playPause = () => {
    $('#player').parent().click(function () {
        if ($(this).children("#player").get(0).paused) {
            $(this).children("#player").get(0).play(); $(this).children("#icon_play ").fadeOut();
        } else {
            $(this).children("#player").get(0).pause();
            $(this).children("#icon_play ").fadeIn();
        }
    });
}
const loadingPage = () => {
    $(window).bind('load', function () {
        $('.overlay, body').addClass('loaded');
        setTimeout(function () {
            $('.overlay').css({ 'display': 'none' })
        }, 500)
    });

    setTimeout(function () {
        $('.overlay, body').addClass('loaded');
    }, 500);
}
const scrollTab = () => {
    if ($(window).width() > 1025) {
        var hidWidth;
        var scrollBarWidths = 40;

        var widthOfList = function () {
            var itemsWidth = 0;
            $('.list li').each(function () {
                var itemWidth = $(this).outerWidth();
                itemsWidth += itemWidth;
            });
            return itemsWidth;
        };

        var widthOfHidden = function () {
            return (($('.wrapper').outerWidth()) - widthOfList() - getLeftPosi()) - scrollBarWidths;
        };

        var getLeftPosi = function () {
            return $('.list').position().left;
        };

        var reAdjust = function () {
            if (($('.wrapper').outerWidth()) < widthOfList()) {
                $('.scroller-right').show();
            }
            else {
                $('.scroller-right').hide();
            }

            if (getLeftPosi() < 0) {
                $('.scroller-left').show();
            }
            else {
                $('.item').animate({ left: "-=" + getLeftPosi() + "px" }, 'slow');
                $('.scroller-left').hide();
            }
        }

        reAdjust();

        $(window).on('resize', function (e) {
            reAdjust();
        });

        $('.scroller-right').click(function () {

            $('.scroller-left').fadeIn('slow');
            $('.scroller-right').fadeOut('slow');

            $('.list').animate({ left: "+=" + widthOfHidden() + "px" }, 'slow', function () {

            });
        });

        $('.scroller-left').click(function () {

            $('.scroller-right').fadeIn('slow');
            $('.scroller-left').fadeOut('slow');

            $('.list').animate({ left: "-=" + getLeftPosi() + "px" }, 'slow', function () {

            });
        });
    }
}
$(document).ready(() => {
    navMenuAction();
    footerActiion();
    swiperInit();
    setBackgroundElement();
    changeAtiveTab();
    scrollToTop();
    playPause();
    loadingPage();
    scrollTab();
    $('select').select2({
        placeholder: "Lựa chọn yêu cầu",
        allowClear: true,
        dropdownParent: $('.form-select')
    });
    Fancybox.bind("[data-fancybox]", {
        on: {
            done: () => {
                $('header').find('.main-nav').removeClass('active');
                $('#toggle').removeClass('active');
                $("#overlay").removeClass("active")
            },
        },
    });
    $(".accordionjs").accordionjs({
        closeAble: true,
        closeOther: true,
        slideSpeed: 150,
        activeIndex: false,
    });

})